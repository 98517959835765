import gql from "graphql-tag";

export const referralsData = gql`
  query referralsData(
    $orderBy: OrderBy
    $filters: Filter
    $agentType: AgentType
    $page: Int
    $perPage: Int
  ) {
    referrals(
      orderBy: $orderBy
      filters: $filters
      agentType: $agentType
      page: $page
      first: $perPage
    ) {
      paginatorInfo {
        total
        perPage
        currentPage
        lastPage
      }

      data {
        id
        name
        phone
        email
        createdAt
        region {
          id
          name
          country {
            id
          }
        }
        user {
          id
          firstName
          lastName
          email
          detail {
            __typename
            ... on Agent {
              phone
              region {
                id
                name
              }
            }
          }
        }
        comment
        status
        accrued
        bx24DealId
        bx24Opportunity
        bx24CurrencyId
        bx24PowerByInvertor
      }
    }

    regions {
      id
      name
    }
  }
`;

<template>
  <div data-cy="referral-form">
    <b-row class="mb-7">
      <b-col cols="6">
        <InputElement
          v-model="form.name"
          data-cy="name-input"
          :disabled="isWonReferral"
          required
          :with-error="errors.name"
          :error-message="$t('validation.required')"
          :label="$t('label.firstName')"
          @blur="removeErrorClass('name')"
          @input="removeErrorClass('name')"
        />
      </b-col>

      <b-col cols="6">
        <CleaveElement
          v-if="!isWonReferral"
          :value="phoneWithPlus"
          :options="cleaveOptions"
          data-cy="phone-input"
          required
          :label="$t('label.phone')"
          :with-error="errors.phone"
          :error-message="phoneErrorMsg"
          @input="onChangePhone"
          @blur="removeErrorClass('phone')"
        />

        <InputElement
          v-else
          v-model="form.phone"
          data-cy="fake-phone-input"
          disabled
          required
          :label="$t('label.phone')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.region)"
          data-cy="region-select"
          :disabled="isWonReferral"
          :options="regions"
          required
          item-label="name"
          :with-error="errors.region"
          :error-message="$t('validation.required')"
          :label="$t('label.region')"
          @select="onSelectRegion"
          @open="removeErrorClass('region')"
        />
      </b-col>

      <b-col cols="6">
        <InputElement
          v-model="form.email"
          data-cy="email-input"
          :disabled="isWonReferral"
          input-type="email"
          :with-error="errors.email"
          :error-message="emailErrorMsg"
          :label="$t('label.email')"
          @blur="removeErrorClass('email')"
          @input="removeErrorClass('email')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-13 mb-7" />

    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.status)"
          :options="statuses"
          type="status"
          :label="$t('label.status')"
          data-cy="status-select"
          :disabled="!isExistReferral"
          required
          :with-error="errors.status"
          :error-message="$t('validation.required')"
          track-by="code"
          @select="onSelectStatus"
          @open="removeErrorClass('status')"
        />
      </b-col>

      <b-col v-if="isExistReferral" cols="6">
        <InputElement disabled :label="$t('label.dateCreate')" :value="form.dateCreate" />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectAgent
          :value="checkValueExists(form.agent)"
          data-cy="agent-select"
          :disabled="isWonReferral"
          :required="isSelectedStatusWon"
          :with-error="errors.agent"
          :error-message="$t('validation.required')"
          @select="onSelectAgent"
          @open="removeErrorClass('agent')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-13 mb-7" />

    <b-row class="mb-7">
      <b-col cols="6">
        <InputElement
          v-model="form.building_area"
          data-cy="name-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.building_area')"
          inputType="number"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.roof_type)"
          :options="referralOptionsRoofTypes"
          :label="$t('referral.form.roof_type')"
          data-cy="roof_type-select"
          track-by="id"
          @select="onSelectRoofType"
          :disabled="isWonReferral"
        />
      </b-col>
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.type_of_roof_covering)"
          :options="referralOptionsTypesOfRoofCovering"
          :label="$t('referral.form.type_of_roof_covering')"
          data-cy="type_of_roof_covering-select"
          track-by="id"
          @select="onSelectTypeOfRoofCovering"
          :disabled="isWonReferral"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-13 mb-7" />

    <b-row class="mb-7">
      <b-col cols="6">
        <InputElement
          v-model="form.consumption_per_month_in_summer"
          data-cy="consumption_per_month_in_summer-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.consumption_per_month_in_summer')"
          inputType="number"
        />
      </b-col>
      <b-col cols="6">
        <InputElement
          v-model="form.consumption_per_month_in_winter"
          data-cy="consumption_per_month_in_winter-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.consumption_per_month_in_winter')"
          inputType="number"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-13 mb-7" />

    <b-row class="mb-16">
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.presence_of_battery)"
          :options="referralOptionsPresenceOfBattery"
          :label="$t('referral.form.presence_of_battery')"
          data-cy="presence_of_battery-select"
          track-by="code"
          @select="onSelectPresenceOfBattery"
          :disabled="isWonReferral"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <InputElement
          v-model="form.number_of_phases_under_contract"
          data-cy="number_of_phases_under_contract-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.number_of_phases_under_contract')"
          inputType="number"
        />
      </b-col>
      <b-col cols="6">
        <InputElement
          v-model="form.number_of_phases_actual"
          data-cy="number_of_phases_actual-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.number_of_phases_actual')"
          inputType="number"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.connection_type)"
          :options="referralOptionsConnectionTypes"
          :label="$t('referral.form.connection_type')"
          data-cy="connection_type-select"
          track-by="id"
          @select="onSelectConnectionType"
          :disabled="isWonReferral"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-13 mb-7" />

    <b-row class="mb-7 mt-12">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.type_of_solar_panel_placement)"
          :options="referralOptionsTypesOfSolarPanelPlacement"
          :label="$t('referral.form.type_of_solar_panel_placement')"
          data-cy="type_of_solar_panel_placement-select"
          track-by="id"
          @select="onSelectTypeOfSolarPanelPlacement"
          :disabled="isWonReferral"
        />
      </b-col>
    </b-row>

    <div v-if="referral.status !== 'won' || referral.briefUrl" class="separator separator-dashed mt-13 mb-7" />

    <Brief v-if="referral.briefUrl" :url="referral.briefUrl"/>

    <b-row v-if="referral.status !== 'won'">
      <b-col cols="6">
        <InputFile
          :label="$t('form.attachBrief')"
          :error="errors.brief"
          :allowed-ext="allowedBriefExt"
          @change="onChangeBrief"
        />
      </b-col>
      <b-col cols="6">
        <ButtonElement
          v-if="briefTemplate?.url"
          variant="primary"
          style="width: 100%;"
          :text="$t('downloadBriefTpl')"
          @click="dowloadBriefTemplate"
        ></ButtonElement>
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-8 mb-7" />

    <b-row class="mb-7">
      <b-col>
        <TextareaElement
          v-model="form.comment"
          :label="$t('label.comment')"
          data-cy="comment"
          :with-error="errors.comment"
          @blur="removeErrorClass('comment')"
          @input="removeErrorClass('comment')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dateMixin from "@/mixins/date.mixin";
import apolloMixin from "@/mixins/apollo.mixin";
import referralStatus from "@/configs/referralStatus.json";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import TextareaElement from "@/components/form/TextareaElement";
import CleaveElement from "@/components/form/CleaveElement";
import MultiselectAgent from "@/components/form/MultiselectAgent/index.vue";
import ButtonElement from "@/components/ButtonElement";
import InputFile from "@/components/form/InputFile";
import Brief from "./Brief.vue";
import { documentFormData } from "../../documents/gql/queries"
import axios from "axios";

export default {
  name: "ReferralForm",
  components: {
    MultiselectAgent,
    InputElement,
    MultiselectElement,
    CleaveElement,
    TextareaElement,
    ButtonElement,
    InputFile,
    Brief,
  },

  mixins: [dateMixin, apolloMixin],

  props: {
    referral: {
      type: Object,
      default: () => ({}),
    },

    agent: {
      type: Object,
      default: () => ({}),
    },

    regions: {
      type: Array,
      default: () => [],
    },

    referralOptions: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    form: {
      dateCreate: "",
      status: "",
      name: "",
      phone: "38",
      email: "",
      region: "",
      agent: null,
      comment: "",
      brief: null,
      building_area: "",
      roof_type: "",
      type_of_roof_covering: "",
      consumption_per_month_in_summer: "",
      consumption_per_month_in_winter: "",
      presence_of_battery: "",
      number_of_phases_under_contract: "",
      number_of_phases_actual: "",
      connection_type: "",
      type_of_solar_panel_placement: "",
    },
    errors: {
      status: false,
      name: false,
      phone: false,
      email: false,
      region: false,
      agent: false,
      comment: false,
      brief: false,
      // building_area: false,
      // roof_type: false,
      // type_of_roof_covering: false,
      // consumption_per_month_in_summer: false,
      // consumption_per_month_in_winter: false,
      // presence_of_battery: false,
      // number_of_phases_under_contract: false,
      // number_of_phases_actual: false,
      // connection_type: false,
      // type_of_solar_panel_placement: false,
    },
    cleaveOptions: {
      phone: true,
      phoneRegionCode: "UA",
      delimiter: "-",
    },
    phoneMinLength: 11,
    phoneMaxLength: 13,
    minPowerKw: 10,
    maxPowerKw: 1000,
    briefTemplate: {},
    allowedBriefExt: ["pdf", "doc", "docx"],
  }),

  async created() {
    await this.getBriefTeplate();
  },

  computed: {
    ...mapState("referral", ["statuses"]),

    isExistReferral() {
      return Object.keys(this.referral).length;
    },

    isWonReferral() {
      return this.referral?.status === referralStatus.won;
    },

    isSelectedStatusWon() {
      return this.form.status?.code === referralStatus.won;
    },

    phoneWithPlus() {
      return `+${this.form.phone}`;
    },

    phoneNumberLength() {
      const phoneRegExp = /\D/g;

      return this.form.phone.replace(phoneRegExp, "").length;
    },

    phoneErrorMsg() {
      return !this.phoneNumberLength
        ? this.$t("validation.required")
        : this.$t("validation.phoneLength");
    },

    emailErrorMsg() {
      return this.form.email ? this.$t("validation.mustBeEmail") : "";
    },

    referralOptionsRoofTypes() {
      return this.referralOptions?.options?.roof_types.map((item) => {
        item.label = this.$t(`referral.form.select.options.roof_types.${item.label}`);

        return item;
      })
    },

    referralOptionsTypesOfRoofCovering() {
      return this.referralOptions?.options?.types_of_roof_covering.map((item) => {
        item.label = this.$t(`referral.form.select.options.types_of_roof_covering.${item.label}`);

        return item;
      })
    },

    referralOptionsConnectionTypes() {
      return this.referralOptions?.options?.connection_types.map((item) => {
        item.label = this.$t(`referral.form.select.options.connection_types.${item.label}`);

        return item;
      })
    },

    referralOptionsTypesOfSolarPanelPlacement() {
      return this.referralOptions?.options?.types_of_solar_panel_placement.map((item) => {
        item.label = this.$t(`referral.form.select.options.types_of_solar_panel_placement.${item.label}`);

        return item;
      })
    },

    referralOptionsPresenceOfBattery() {
      return [
        {id: 1, label: this.$t(`referral.form.select.options.presence_of_battery._1`)},
        {id: 0, label: this.$t(`referral.form.select.options.presence_of_battery._0`)},
      ];
    },
  },

  watch: {
    referral: {
      handler: "onChangeReferral",
      immediate: true,
      deep: true,
    },
  },

  methods: {
    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    onChangeReferral() {
      if (this.isExistReferral) {

        const dateCreate = this.$dateConverter(this.referral.createdAt);
        const name = this.referral.name;
        const phone = this.referral.phone;
        const email = this.referral.email || "";
        const region = this.referral.region;
        const status = this.statuses.find((status) => status.code === this.referral.status);
        const comment = this.referral.comment;
        const agent = this.agent;
        const brief = this.form.brief;
        const building_area = this.referral.buildingArea;
        const roof_type = this.referral.roofType ? {
          id: this.referral.roofType,
          label: this.$t(`referral.form.select.options.roof_types.${this.referral.roofType}`)
        } : "";

        const type_of_roof_covering = this.referral.typeOfRoofCovering ? {
          id: this.referral.typeOfRoofCovering,
          label: this.$t(`referral.form.select.options.types_of_roof_covering.${this.referral.typeOfRoofCovering}`)
        } : "";

        const consumption_per_month_in_summer = this.referral.consumptionPerMonthInSummer;
        const consumption_per_month_in_winter = this.referral.consumptionPerMonthInWinter;
        
        const presence_of_battery = this.referral.presenceOfBattery ? {
          id: this.referral.presenceOfBattery,
          label: this.$t(`referral.form.select.options.presence_of_battery._${this.referral.presenceOfBattery}`)
        } : "";

        const number_of_phases_under_contract = this.referral.numberOfPhasesUnderContract;
        const number_of_phases_actual = this.referral.numberOfPhasesActual;

        const connection_type = this.referral.connectionType ? {
          id: this.referral.connectionType,
          label: this.$t(`referral.form.select.options.connection_types.${this.referral.connectionType}`)
        } : "";

        const type_of_solar_panel_placement = this.referral.typeOfSolarPanelPlacement ? {
          id: this.referral.typeOfSolarPanelPlacement,
          label: this.$t(
            `referral.form.select.options.types_of_solar_panel_placement.${this.referral.typeOfSolarPanelPlacement}`
          )
        } : "";

        this.form = {
          dateCreate,
          status,
          name,
          phone,
          email,
          region,
          agent,
          comment,
          brief,
          building_area,
          roof_type,
          type_of_roof_covering,
          consumption_per_month_in_summer,
          consumption_per_month_in_winter,
          presence_of_battery,
          number_of_phases_under_contract,
          number_of_phases_actual,
          connection_type,
          type_of_solar_panel_placement,
        };

      } else {
        this.form.status = this.statuses.find((status) => status.code === referralStatus.new);
      }
    },

    onChangePhone(phone) {
      this.form.phone = phone;
      this.removeErrorClass("phone");
    },

    onSelectStatus(status) {
      this.form.status = status;
    },

    onSelectRegion(region) {
      this.form.region = region;
    },

    onSelectAgent(agent) {
      this.form.agent = agent;
    },

    onSelectRoofType(val) {
      this.form.roof_type = val
    },

    onSelectTypeOfRoofCovering(val) {
      this.form.type_of_roof_covering = val
    },

    onSelectPresenceOfBattery(val) {
      this.form.presence_of_battery = val
    },
    
    onSelectConnectionType(val) {
      this.form.connection_type = val
    },

    onSelectTypeOfSolarPanelPlacement(val) {
      this.form.type_of_solar_panel_placement = val
    },

    onChangeBrief(e) {
      if(typeof e !== 'undefined') {
        const briefExt = e.name.split(".")[e.name.split(".").length - 1].toLowerCase();

        this.errors.brief = !this.allowedBriefExt.includes(briefExt);
      }
      
      this.form.brief = e
    },

    isValidForm() {
      const emailRegExp = /.+@.+\..+/i;

      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.status = !this.form.status;
      this.errors.region = !this.form.region;
      this.errors.name = !this.form.name;
      this.errors.phone = !this.phoneNumberLength;
      this.errors.phone =
        this.phoneNumberLength < this.phoneMinLength ||
        this.phoneNumberLength > this.phoneMaxLength;
      if (this.form.email) this.errors.email = !this.form.email.match(emailRegExp);
      if (this.isSelectedStatusWon) this.errors.agent = !this.form.agent;

      if(this.form.brief) {
        const briefExt = this.form.brief.name.split(".")[this.form.brief.name.split(".").length - 1].toLowerCase();

        this.errors.brief = !this.allowedBriefExt.includes(briefExt);
      }

      // this.errors.building_area = !this.form.building_area;
      // this.errors.roof_type = !this.form.roof_type;
      // this.errors.type_of_roof_covering = !this.form.type_of_roof_covering;
      // this.errors.consumption_per_month_in_summer = !this.form.consumption_per_month_in_summer;
      // this.errors.consumption_per_month_in_winter = !this.form.consumption_per_month_in_winter;
      // this.errors.presence_of_battery = !this.form.presence_of_battery;
      // this.errors.number_of_phases_under_contract = !this.form.number_of_phases_under_contract;
      // this.errors.number_of_phases_actual = !this.form.number_of_phases_actual;
      // this.errors.connection_type = !this.form.connection_type;
      // this.errors.type_of_solar_panel_placement = !this.form.type_of_solar_panel_placement;

      return !Object.values(this.errors).some((error) => error);
    },

    submitForm() {
      if (!this.isValidForm()) return;

      let referralData = {
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        regionId: this.form.region.id,
        userId: this.form.agent?.id,
        agentHash: this.form.agent?.agentHash,
        comment: this.form.comment,
        status: this.form.status.code,
        brief: this.form.brief,
        buildingArea: parseFloat(this.form.building_area),
        roofType: this.form.roof_type.id,
        typeOfRoofCovering: this.form.type_of_roof_covering.id,
        consumptionPerMonthInSummer: parseInt(this.form.consumption_per_month_in_summer),
        consumptionPerMonthInWinter: parseInt(this.form.consumption_per_month_in_winter),
        presenceOfBattery: this.form.presence_of_battery.id,
        numberOfPhasesUnderContract: parseInt(this.form.number_of_phases_under_contract),
        numberOfPhasesActual: parseInt(this.form.number_of_phases_actual),
        connectionType: this.form.connection_type.id,
        typeOfSolarPanelPlacement: this.form.type_of_solar_panel_placement.id,
      };

      this.$emit("submitForm", referralData);
    },

    async getBriefTeplate() {
      const response = await this.$get(documentFormData, { name: "brief" });

      this.briefTemplate = response.document;
    },

    dowloadBriefTemplate() {
      if(this.briefTemplate?.url) {

        axios.get(`storage/private/${this.briefTemplate.url}`, {
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");

          link.href = href;
          link.setAttribute("download", this.briefTemplate.filename);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-group::v-deep {
  margin-bottom: 0;
}

[data-cy=number_of_phases_under_contract-input]::v-deep label,
[data-cy=number_of_phases_actual-input]::v-deep label {
  margin-top: -20px;
  margin-right: 20px;
}
</style>

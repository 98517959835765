<template>
  <div>
    <KTCard v-if="referral" data-cy="referral-edit-page">
      <PageModal
        ref="transactionsModal"
        modal-id="transactionsModal"
        :title="$t('label.accruals.history')"
        modal-size="lg"
        is-centered
      >
        <OurTable
          table-classes="our-table table-responsive"
          :fields="fields"
          :items="agentTransactions"
        />

        <template v-slot:footer>
          <ButtonElement
            variant="text-primary"
            text="back"
            @click="closeTransactionsModal"
          />
        </template>
      </PageModal>

      <b-row class="mb-7">
        <b-col cols="12">
          <div class="accruals">
            {{ $t("label.accruals.order") }} {{ accrualsAmount }}
          </div>
          <b-link
            v-if="referral.transactions.length"
            class="accruals-link"
            @click="showTransactionsModal"
          >
            {{ $t("button.accrualsHistory") }}
          </b-link>
        </b-col>
      </b-row>

      <div class="separator separator-dashed my-7" />

      <ReferralForm
        ref="referralForm"
        :referral="referral"
        :regions="regions"
        :agent="agent"
        :referralOptions="referralOptions"
        @submitForm="onSubmitForm"
      />

      <div class="separator separator-dashed my-7" />

      <b-row class="mb-7">
        <b-col cols="12">
          <span class="agreement">{{ $t("label.agreement") }}:</span>
          <b-link
            class="agreement-link"
            :href="referral.bx24DealUrl"
            target="_blank"
          >
            {{ referral.bx24DealUrl }}
          </b-link>
        </b-col>
      </b-row>

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div>
            <ButtonElement
              data-cy="submit-btn"
              variant="primary"
              text="save"
              class="mr-3"
              @click="onClickSubmit"
            />

            <ButtonElement
              data-cy="add-btn"
              variant="secondary"
              class="mr-3"
              :text="$t('addTransaction')"
              @click="onClickAdd"
            />

            <ButtonElement
              data-cy="back-btn"
              variant="text-primary"
              text="back"
              @click="onClickBack"
            />
          </div>

          <ButtonElement
            v-b-modal.confirm-modal
            data-cy="delete-btn"
            variant="text-danger"
            text="delete"
          />
        </div>
      </template>
    </KTCard>

    <ConfirmModal
      :modal-title="$t('page.referralEdit.modalTitle')"
      is-centered
      @actionConfirmed="onConfirmDeleteReferral"
    >
      <template v-slot:body>
        {{ $t("page.referralEdit.modalDescription") }}
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { referralFormData, agentData } from "./gql/queries";
import { updateReferral, deleteReferral } from "./gql/mutations";
import KTCard from "@/components/card/KTCard";
import ReferralForm from "../_components/ReferralForm";
import ButtonElement from "@/components/ButtonElement";
import ConfirmModal from "@/components/modal/ConfirmModal";
import PageModal from "@/components/modal/PageModal";
import OurTable from "@/components/table/OurTable";

const CONTENT_WIDTH = 700;

export default {
  name: "ReferralEdit",
  components: {
    KTCard,
    ReferralForm,
    ButtonElement,
    ConfirmModal,
    PageModal,
    OurTable,
  },

  mixins: [apolloMixin],

  data() {
    return {
      referralId: null,
      referral: null,
      agent: {},
      regions: [],
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "name",
          label: this.$t("label.agent._"),
          type: "link",
        },
        {
          key: "amount",
          label: this.$t("label.amount"),
          type: "amount",
          thClass: "text-right",
          tdAttr: {
            style: "width: 20%; max-width: 90px; text-align: right;",
          },
          filter: {
            component: "input",
            type: "number",
            operator: "like",
            classes: "w-50",
          },
        },
        {
          key: "type",
          label: this.$t("label.type"),
          tdAttr: {
            style: "width: 30%; max-width: 100px;",
          },
          filter: {
            component: "select",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "comment",
          label: this.$t("label.comment"),
          tdAttr: {
            style: "width: 40%; max-width: 110px;",
          },
        },
      ],
      referralOptions: {},
    };
  },

  computed: {
    ...mapState("transaction", ["types"]),
    ...mapState("currency", ["currency"]),

    locale() {
      return this.$i18n.locale;
    },

    accrualsAmount() {
      return `${this.referral.accrued} ${this.currency}`;
    },

    agentTransactions() {
      return this.referral.transactions
        .map((transaction) => {
          const dateCreate = transaction.createdAt;
          const amount = {
            amount: transaction.amount,
            type: transaction.type,
          };

          const type = this.types.find(
            (type) => type.code === transaction.type
          )?.label;
          const comment = transaction.comment || "–";
          const name = {
            title: transaction.user
            ? `${transaction.user.firstName} ${transaction.user.lastName}`
            : `${this.referral.user.firstName} ${this.referral.user.lastName}`,
            route: {
              name: "AgentEdit",
              params: { id: transaction.user ? transaction.user.id : this.referral.user.id },
            },
          };

          return {
            dateCreate,
            name,
            amount,
            type,
            comment,
          };
        })
        .reverse();
    },
  },

  async created() {
    this.referralId = this.$route.params.id;

    await this.getFormData();
  },

  mounted() {
    this.$setBreadcrumbs(["ReferralList"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getFormData() {
      const { referral, regions, referralOptions } = await this.$get(referralFormData, {
        id: this.referralId,
      });

      const userId = referral.user?.id;

      this.referralOptions = referralOptions;

      if (userId) {
        const { agent } = await this.$get(agentData, { userId });

        this.agent = {
          id: agent.user.id,
          label: `${agent.user.firstName} ${agent.user.lastName}`,
        };
      }

      // TODO: fix when will be country switch
      // referral.region.name = referral.region.name[this.locale];
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));

      referral.region.name = referral.region.name["ua"];

      this.referral = referral;
      this.regions = regions.map((region) => ({
        ...region,
        name: region.name["ua"],
      }));
    },

    showTransactionsModal() {
      this.$refs.transactionsModal.showModal();
    },

    closeTransactionsModal() {
      this.$refs.transactionsModal.closeModal();
    },

    onClickSubmit() {
      this.$refs.referralForm.submitForm();
    },

    async onSubmitForm(referralData) {
      referralData = {
        ...referralData,
        id: this.referralId,
      };

      const response = await this.$post(updateReferral, referralData);

      this.referral.briefUrl = response.updateReferral.briefUrl;
    },

    onClickAdd() {
      this.$router.push({
        name: "EmployeeAddTransaction",
        params: { id: this.referralId },
      });
    },

    onClickBack() {
      this.$router.push({ name: "ReferralList" });
    },

    async onConfirmDeleteReferral() {
      const deletedReferral = await this.$post(
        deleteReferral,
        { id: this.referralId },
        { delaySuccessNotify: true }
      );

      if (deletedReferral) await this.$router.push({ name: "ReferralList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.accruals {
  font-size: 1.2rem;
  font-weight: 700;

  &-link {
    font-size: 1rem;
    font-weight: 500;
  }
}

.agreement {
  font-size: 1.1rem;
  font-weight: 700;
  margin-right: 5px;

  &-link {
    font-size: 1rem;
    font-weight: 500;
  }
}
</style>

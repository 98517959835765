<template>
  <div data-cy="agent-referrals-page">
    <div class="d-flex justify-content-between mb-4">
      <ButtonElement
        data-cy="filters-btn"
        no-focus
        :variant="filtersBtnVariant()"
        text="filters"
        @click="onClickFilters"
      >
        <span>{{ $t("button.filters") }}</span>
        <span>{{ filtersCounter() }}</span>
      </ButtonElement>

      <ButtonElement
        data-cy="add-referral-btn"
        variant="primary"
        text="add"
        @click="onClickAddReferral"
      />
    </div>

    <KTCard card-class="card-table">
      <OurTable
        data-cy="agent-referrals-table"
        table-classes="our-table table-responsive"
        :pagination-data="paginationData"
        :items="agentReferrals"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="getReferrals"
      >
        <template v-slot:cell-actions="{ scopeProps }">
          <SvgIcon
            data-cy="referral-details-btn"
            :icon-folder="userAgentType === 'company' ? 'Design' : 'Communication'"
            :icon-name="userAgentType === 'company' ? 'Edit' : 'Clipboard-list'"
            icon-color="primary"
            icon-size="md"
            @click="onShowReferralDetails(scopeProps.item)"
          />
        </template>
      </OurTable>
    </KTCard>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import dateMixin from "@/mixins/date.mixin";
import { agentReferrals } from "./gql/queries";
import { serializeFilters } from "@/services/filterBuilder.service";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import SvgIcon from "@/components/svg/SvgIcon";
import ButtonElement from "@/components/ButtonElement";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";

const CONTENT_WIDTH = 1200;

export default {
  name: "AgentReferrals",
  components: {
    KTCard,
    OurTable,
    SvgIcon,
    ButtonElement,
    PageModal,
    OurFilters,
  },

  mixins: [apolloMixin, dateMixin],

  data() {
    return {
      regions: [],
      paginationData: {},
      referrals: [],
      appliedFilters: {},
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "name",
          label: this.$t("label.firstName"),
          tdAttr: {
            style: "width: 28%; max-width: 110px;",
          },
          filter: {
            component: "input",
            type: "text",
            operator: "like",
          },
        },
        {
          key: "phone",
          label: this.$t("label.phone"),
          filter: {
            component: "input",
            type: "number",
            operator: "like",
          },
        },
        {
          key: "email",
          label: "E-mail",
          type: "email",
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
          filter: {
            component: "input",
            type: "text",
            operator: "like",
          },
        },
        {
          key: "status",
          label: this.$t("label.status"),
          type: "status",
          tdAttr: {
            style: "width: 15%; max-width: 135px;",
          },
          filter: {
            component: "select",
            type: "status",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "region",
          label: this.$t("label.region"),
          tdAttr: {
            style: "width: 22%; max-width: 138px;",
          },
          filter: {
            component: "select",
            column: "region.id",
            operator: "=",
          },
        },
        {
          key: "accrued",
          label: this.$t("label.accruals.amount"),
          tdAttr: {
            style: "width: 22%; max-width: 138px;",
          },
        },
        {
          key: "powerByInvertor",
          label: this.$t("label.station.power"),
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
        },
        {
          key: "opportunity",
          label: this.$t("label.deal.opportunity"),
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 80px;",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("referral", ["statuses"]),
    ...mapState("agent", ["referralsFilters"]),
    ...mapState("currency", ["currency"]),
    ...mapGetters(["userAgentType"]),

    agentReferrals() {
      return this.referrals.map((referral) => {
        const accrued = referral.accrued ? `${referral.accrued} ${this.currency}` : "–";
        const opportunityCurrency = referral.bx24CurrencyId ? this.$t(`currency.${referral.bx24CurrencyId}`) : "";
        const opportunity = referral.bx24Opportunity ? `${referral.bx24Opportunity} ${opportunityCurrency}` : "–";
        const power = referral.bx24PowerByInvertor ? 
          this.$t('label.station.powerWithKW', { power: referral.bx24PowerByInvertor })
          : "–";

        return {
          id: referral.id,
          dateCreate: referral.createdAt,
          name: referral.name,
          phone: referral.phone,
          email: referral.email,
          status: this.statuses.find((item) => item.code === referral.status),

          // TODO: fix when will be country switch
          // region: referral.region?.name[this.locale],
          region: referral.region?.name["ua"],
          comment: referral.comment,
          powerByInvertor: power,
          opportunity: opportunity,
          accrued,
        };
      });
    },

    fieldsForFilters() {
      return this.fields.map((field) => {
        if (field.key === "status") {
          field.filter.selectOptions = this.statuses;
          field.filter.trackBy = "code";
        }

        if (field.key === "region") {
          field.filter.selectOptions = this.regions.map((region) => ({
            ...region,
            //TODO: fix when will be country switch
            // name: region.name[this.locale],
            name: region.name["ua"],
          }));
          field.filter.itemLabel = "name";
        }

        return field;
      });
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  async created() {
    if (this.referralsFilters) this.appliedFilters = this.referralsFilters;
    await this.getReferrals();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapMutations("agent", ["SET_REFERRALS_FILTERS"]),

    async getReferrals(page) {
      const sortingOptions = {
        column: "createdAt",
        direction: "desc",
      };
      let filtersOptions = [];
      const isAppliedFilters = !!Object.keys(this.appliedFilters).length;

      if (page && page === this.paginationData.currentPage) return;

      if (isAppliedFilters) {
        filtersOptions = serializeFilters(this.fields, this.appliedFilters);
      }

      const { referrals, regions } = await this.$get(agentReferrals, {
        filtersOptions,
        sortingOptions,
        page,
      });

      this.paginationData = referrals.paginatorInfo;
      this.referrals = referrals.data;
      this.regions = regions;
    },

    onClickFilters() {
      this.$refs.filtersModal.showModal();
    },

    onClickAddReferral() {
      this.$router.push({ name: "AgentReferralAdd" });
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    async onApplyFilters(filters) {
      this.appliedFilters = filters;
      this.SET_REFERRALS_FILTERS(filters);

      await this.getReferrals();
    },

    async onResetFilters() {
      this.appliedFilters = {};
      const filters = null;

      this.$refs.filtersModal.closeModal();
      this.SET_REFERRALS_FILTERS(filters);

      await this.getReferrals();
    },

    onShowReferralDetails({ id }) {
      this.$router.push({ name: "ReferralDetails", params: { id } });
    },

    filtersBtnVariant() {
      const isAppliedFilters = !!Object.keys(this.appliedFilters).length;

      return isAppliedFilters ? "dark" : "outline-dark";
    },

    filtersCounter() {
      const counter = Object.keys(this.appliedFilters).length;

      return counter ? ` · ${counter}` : "";
    },
  },
};
</script>

<template>
  <b-row>
    <b-col cols="12">
      <div class="attached-brief form-control">
          <div class="attached-brief-title">{{ $t("form.brief") }}</div>
          <div class="attached-brief-row">
            <div class="attached-brief-left">
                <SvgIcon
                  icon-folder="General"
                  icon-name="File"
                  icon-size="xl"
                />
                <div class="attached-brief-name">
                {{ filename }}
                </div>
            </div>
            <div class="attached-brief-right">
                <ButtonElement
                  variant="primary"
                  :text="$t('download')"
                  @click="dowloadBrief"
                />
            </div>
          </div>
      </div>
    </b-col>
</b-row>
</template>

<script>
import ButtonElement from "@/components/ButtonElement";
import SvgIcon from "@/components/svg/SvgIcon";
import axios from "axios"

export default {
  name: "Brief",

  components: {
    ButtonElement,
    SvgIcon,
  },

  props: {
    url: {
      type: String,
      default: "",
    },
  },

  computed: {
    filename() {
      return this.url.split("/")[this.url.split("/").length - 1];
    }
  },

  methods: {
    dowloadBrief() {
      if(this.url) {

        axios.get(`/storage/private/${this.url}`, {
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          const filename = this.filename;

          link.href = href;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attached-brief {
  margin-bottom: 3rem;
  
  &.form-control {
    border-radius: 0.25rem;
    font-weight: 400;
    position: relative;
    height: auto;
  }

  &-title {
    font-size: 1.07rem;
    margin-left: 5px;
    padding: 0 5px;
    position: absolute;
    left: 10px;
    top: -15px;
    background-color: white;
    border-radius: 0.25rem;
    z-index: 1;
    font-weight: 400;
  }

  &-row {
    display: flex;
    align-items: center;
  }

  &-left {
    display: flex;
    align-items: center;
    flex: 100 0 0;
  }
}
</style>

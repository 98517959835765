<template>
  <KTCard data-cy="referral-add-page">
    <ReferralForm
      ref="referralForm"
      :user-hash="userHash"
      :regions="regions"
      :referralOptions="referralOptions"
      formActionType="create"
      @submitForm="onSubmitForm"
    />

    <template v-slot:footer>
      <div class="d-flex justify-content-start">
        <ButtonElement
          data-cy="submit-btn"
          variant="primary"
          text="save"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement data-cy="back-btn" variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import storage from "@/services/storage.service";
import apolloMixin from "@/mixins/apollo.mixin";
import KTCard from "@/components/card/KTCard";
import ReferralForm from "./components/ReferralForm";
import ButtonElement from "@/components/ButtonElement";
import { regionsQuery, requiredData } from "@/views/referral/form/gql/queries";

const CONTENT_WIDTH = 700;

export default {
  name: "AgentReferralAdd",
  components: {
    KTCard,
    ReferralForm,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    regions: [],
    referralOptions: {},
  }),

  computed: {
    ...mapGetters(["userHash"]),

    locale() {
      return this.$i18n.locale;
    },
  },

  async created() {
    await this.getRegions();
    await this.getRequiredData();
  },

  mounted() {
    this.$setBreadcrumbs(["AgentReferrals"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapActions("publicReferral", ["createReferral"]),

    async getRegions() {
      const { regions } = await this.$get(regionsQuery);

      // TODO: fix when will be country switch
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));
      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
    },

    async getRequiredData() {
      const { referralOptions } = await this.$get(requiredData);

      this.referralOptions = referralOptions;
    },

    onClickSubmit() {
      this.$refs.referralForm.submitForm();
    },

    async onSubmitForm(referralData) {
      const response = await this.createReferral(referralData);

      storage.setObj("notify", { type: "success", message: response.data.message });

      await this.$router.push({ name: "AgentReferrals" });
    },

    onClickBack() {
      this.$router.push({ name: "AgentReferrals" });
    },
  },
};
</script>

import gql from "graphql-tag";

export const referralById = gql`
  query referral($id: ID!) {
    referral(id: $id) {
      id
      name
      phone
      email
      comment
      status
      createdAt
      region {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      stationPlanned
      stationInstalled
      transactions {
        id
        type
        amount
        createdAt
        comment
        user {
          id
          firstName
          lastName
        }
      }
      accrued
      briefUrl
      buildingArea
      roofType
      typeOfRoofCovering
      consumptionPerMonthInSummer
      consumptionPerMonthInWinter
      presenceOfBattery
      numberOfPhasesUnderContract
      numberOfPhasesActual
      connectionType
      typeOfSolarPanelPlacement
      manager
    }
  }
`;

<template>
  <div data-cy="referral-list-page">
    <div class="d-flex justify-content-between mb-4">
      <ButtonElement
        data-cy="filters-btn"
        no-focus
        :variant="filtersBtnVariant()"
        text="filters"
        @click="onClickFilters"
      >
        <span>{{ $t("button.filters") }}</span>
        <span>{{ filtersCounter() }}</span>
      </ButtonElement>

      <ButtonElement
        data-cy="add-referral-btn"
        variant="primary"
        text="add"
        @click="onClickAddReferral"
      />
    </div>

    <KTCard card-class="card-table">
      <OurTable
        data-cy="referrals-table"
        table-classes="our-table table-responsive"
        :pagination-data="paginationData"
        :items="allReferrals"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="getReferrals"
      >
        <template v-slot:cell-actions="{ scopeProps }">
          <SvgIcon
            data-cy="edit-referral-btn"
            icon-folder="Design"
            icon-name="Edit"
            icon-color="primary"
            icon-size="md"
            @click="onClickEditReferral(scopeProps.item)"
          />
        </template>
      </OurTable>
    </KTCard>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import dateMixin from "@/mixins/date.mixin";
import { referralsData } from "./gql/queries";
import { serializeFilters } from "@/services/filterBuilder.service";
import DateService from "@/services/date.service";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import SvgIcon from "@/components/svg/SvgIcon";
import ButtonElement from "@/components/ButtonElement";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";

const CONTENT_WIDTH = 1400;

export default {
  name: "ReferralList",
  components: {
    KTCard,
    OurTable,
    SvgIcon,
    ButtonElement,
    PageModal,
    OurFilters,
  },

  mixins: [apolloMixin, dateMixin],

  data() {
    return {
      regions: [],
      paginationData: {},
      referrals: [],
      appliedFilters: {},
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "name",
          label: this.$t("label.firstName"),
          tdAttr: {
            style: "width: 17%; max-width: 100px;",
          },
        },
        {
          key: "phone",
          label: this.$t("label.phone"),
        },
        {
          key: "status",
          label: this.$t("label.status"),
          type: "status",
          tdAttr: {
            style: "width: 17%; max-width: 110px;",
          },
        },
        {
          key: "region",
          label: this.$t("label.region"),
          tdAttr: {
            style: "width: 20%; max-width: 120px;",
          },
        },
        {
          key: "agent",
          label: this.$t("label.agent._"),
          type: "link",
          tdAttr: {
            style: "width: 17%; max-width: 100px;",
          },
        },
        {
          key: "agreementId",
          label: this.$t("label.agreement"),
          tdAttr: {
            style: "width: 40%; max-width: 120px;",
          },
        },
        {
          key: "accrued",
          label: this.$t("label.accruals.amount"),
          tdAttr: {
            style: "width: 20%; max-width: 120px;",
          },
        },
        {
          key: "powerByInvertor",
          label: this.$t("label.station.power"),
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
        },
        {
          key: "opportunity",
          label: this.$t("label.deal.opportunity"),
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
        },
        {
          key: "email",
          label: "E-mail",
          type: "email",
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 80px;",
          },
        },
      ],
      filterFields: [
        {
          key: "name",
          label: this.$t("label.firstName"),
          filter: {
            component: "input",
            type: "text",
            operator: "like",
          },
        },
        {
          key: "phone",
          label: this.$t("label.phone"),
          filter: {
            component: "input",
            type: "number",
            operator: "like",
          },
        },
        {
          key: "email",
          label: this.$t("label.email"),
          type: "email",
          filter: {
            component: "input",
            type: "text",
            operator: "like",
          },
        },
        {
          key: "status",
          label: this.$t("label.status"),
          type: "status",
          filter: {
            component: "select",
            type: "status",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "region",
          label: this.$t("label.region"),
          filter: {
            component: "select",
            column: "region.id",
            operator: "=",
          },
        },
        {
          key: "agent",
          label: this.$t("label.agent._"),
          type: "link",
          filter: {
            component: "select-agent",
            column: "user.id",
            operator: "=",
          },
        },
        {
          key: "agentType",
          label: this.$t("label.agentType"),
          filter: {
            component: "select",
          },
        },
        {
          key: "dateInterval",
          label: {
            dateFrom: this.$t("label.dateFrom"),
            dateTo: this.$t("label.dateTo"),
          },
          filter: {
            component: "datepicker-range",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("referral", ["statuses"]),
    ...mapState("employee", ["referralsFilters", "agentTypes"]),
    ...mapState("currency", ["currency"]),

    allReferrals() {
      return this.referrals.map((referral) => {
        const accrued = referral.accrued ? `${referral.accrued} ${this.currency}` : "–";
        const opportunityCurrency = referral.bx24CurrencyId ? this.$t(`currency.${referral.bx24CurrencyId}`) : "";
        const opportunity = referral.bx24Opportunity ? `${referral.bx24Opportunity} ${opportunityCurrency}` : "–";
        const power = referral.bx24PowerByInvertor ? 
          this.$t('label.station.powerWithKW', { power: referral.bx24PowerByInvertor })
          : "–";

        let referralData = {
          id: referral.id,
          dateCreate: referral.createdAt,
          name: referral.name,
          phone: referral.phone,
          email: referral.email,
          status: this.statuses.find((item) => item.code === referral.status),
          // TODO: fix when will be country switch
          // region: referral.region?.name[this.locale],
          region: referral.region?.name["ua"],
          agent: "–",
          comment: referral.comment,
          agreementId: referral.bx24DealId,
          powerByInvertor: power,
          opportunity: opportunity,
          accrued,
        };

        if (referral.user) {
          referralData = {
            ...referralData,
            agent: {
              title: `${referral.user.firstName} ${referral.user.lastName}`,
              route: {
                name: "AgentEdit",
                params: { id: referral.user.id },
              },
            },
          };
        }

        return referralData;
      });
    },

    fieldsForFilters() {
      return this.filterFields.map((field) => {
        if (field.key === "status") {
          field.filter.selectOptions = this.statuses;
          field.filter.trackBy = "code";
        }

        if (field.key === "region") {
          field.filter.selectOptions = this.regions.map((region) => ({
            ...region,
            //TODO: fix when will be country switch
            // name: region.name[this.locale],
            name: region.name["ua"],
          }));
          field.filter.itemLabel = "name";
        }

        if (field.key === "agentType") {
          field.filter.selectOptions = this.agentTypes;
          field.filter.trackBy = "slug";
        }

        return field;
      });
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  async created() {
    if (this.referralsFilters) this.appliedFilters = this.referralsFilters;
    await this.getReferrals();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapMutations("employee", ["SET_REFERRALS_FILTERS"]),

    async getReferrals(page) {
      const sortingOptions = {
        column: "createdAt",
        direction: "desc",
      };

      const appliedFilters = { ...this.appliedFilters };
      const filterFields = this.filterFields.slice(0);
      const isAppliedFilters = !!Object.keys(appliedFilters).length;

      let filtersOptions = [];
      let options = {};

      if (page && page === this.paginationData.currentPage) return;

      if (isAppliedFilters) {
        if (appliedFilters.agentType) {
          const { agentType, dateInterval } = appliedFilters;

          options.agentType = {
            slug: agentType,
            dateFrom: DateService.getDateTimestamp(dateInterval?.from).toString(),
            dateTo: DateService.getDateTimestamp(dateInterval?.to).toString(),
          };

          delete appliedFilters.agentType;
        }

        filtersOptions = serializeFilters(filterFields, appliedFilters).flat();
      }

      options = { ...options, filtersOptions, sortingOptions, page };

      const { referrals, regions } = await this.$get(referralsData, options);

      this.paginationData = referrals.paginatorInfo;
      this.referrals = referrals.data;
      this.regions = regions;
    },

    onClickFilters() {
      this.$refs.filtersModal.showModal();
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    async onApplyFilters(filters) {
      this.appliedFilters = filters;
      this.SET_REFERRALS_FILTERS(filters);

      await this.getReferrals();
    },

    async onResetFilters() {
      this.appliedFilters = {};
      const filters = null;

      this.$refs.filtersModal.closeModal();
      this.SET_REFERRALS_FILTERS(filters);

      await this.getReferrals();
    },

    onClickAddReferral() {
      this.$router.push({ name: "ReferralAdd" });
    },

    onClickEditReferral({ id }) {
      this.$router.push({ name: "ReferralEdit", params: { id } });
    },

    filtersBtnVariant() {
      const isAppliedFilters = !!Object.keys(this.appliedFilters).length;

      return isAppliedFilters ? "dark" : "outline-dark";
    },

    filtersCounter() {
      const counter = Object.keys(this.appliedFilters).length;

      return counter ? ` · ${counter}` : "";
    },
  },
};
</script>

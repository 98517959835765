<template>
  <div data-cy="ref-form" class="referral-form">
    <InputElement
      v-model="form.firstName"
      data-cy="first-name-input"
      class="component-input"
      size="lg"
      :disabled="isWonReferral"
      :with-error="errors.firstName"
      :error-message="$t('validation.required')"
      :label="$t('label.fullName')"
      @blur="removeErrorClass('firstName')"
      @input="removeErrorClass('firstName')"
    />

    <CleaveElement
      v-if="!isWonReferral"
      :value="phoneWithPlus"
      :options="cleaveOptions"
      data-cy="phone-input"
      class="component-input"
      size="lg"
      :disabled="isWonReferral"
      :label="$t('label.phone')"
      :with-error="errors.phone"
      :error-message="phoneErrorMsg"
      @input="onChangePhone"
      @blur="removeErrorClass('phone')"
    />

    <InputElement
      v-else
      v-model="form.phone"
      data-cy="fake-phone-input"
      disabled
      required
      :label="$t('label.phone')"
    />

    <MultiselectElement
      :value="checkValueExists(form.region)"
      data-cy="region-select"
      class="component-input-select"
      size="lg"
      :options="regions"
      item-label="name"
      :disabled="isWonReferral"
      :with-error="errors.region"
      :error-message="$t('validation.required')"
      :label="$t('label.region')"
      @select="onSelectRegion"
      @open="removeErrorClass('region')"
    />

    <div class="separator separator-dashed my-7" v-if="isExistReferral" />
    
    <b-row v-if="isExistReferral">
      <b-col cols="6">
        <ReadOnlyElement
          :label="$t('label.date')"
          :text="form.dateCreate"
        />
      </b-col>

      <b-col cols="6">
        <ReadOnlyElement :label="$t('label.status')">
          <template v-slot:body>
            <StatusItem
              :color="form.status.color"
              :label="form.status.label"
              class="text"
            />
          </template>
        </ReadOnlyElement>
      </b-col>
    </b-row>

    <div class="separator separator-dashed my-7" v-if="isExistReferral" />
    <b-row class="mb-7">
      <b-col cols="6">
        <InputElement
          v-model="form.building_area"
          data-cy="building_area-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.building_area')"
          inputType="number"
          :required="toValidate ? true : false"
          :with-error="errors.building_area"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('building_area')"
          @input="removeErrorClass('building_area')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.roof_type)"
          :options="referralOptionsRoofTypes"
          :label="$t('referral.form.roof_type')"
          data-cy="roof_type-select"
          track-by="id"
          @select="onSelectRoofType"
          :disabled="isWonReferral"
          :required="toValidate ? true : false"
          :with-error="errors.roof_type"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('roof_type')"
          @input="removeErrorClass('roof_type')"
        />
      </b-col>
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.type_of_roof_covering)"
          :options="referralOptionsTypesOfRoofCovering"
          :label="$t('referral.form.type_of_roof_covering')"
          data-cy="type_of_roof_covering-select"
          track-by="id"
          @select="onSelectTypeOfRoofCovering"
          :disabled="isWonReferral"
          :required="toValidate ? true : false"
          :with-error="errors.type_of_roof_covering"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('type_of_roof_covering')"
          @input="removeErrorClass('type_of_roof_covering')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mb-7" />

    <b-row class="mb-7">
      <b-col cols="6">
        <InputElement
          v-model="form.consumption_per_month_in_summer"
          data-cy="consumption_per_month_in_summer-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.consumption_per_month_in_summer')"
          inputType="number"
          :required="toValidate ? true : false"
          :with-error="errors.consumption_per_month_in_summer"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('consumption_per_month_in_summer')"
          @input="removeErrorClass('consumption_per_month_in_summer')"
        />
      </b-col>
      <b-col cols="6">
        <InputElement
          v-model="form.consumption_per_month_in_winter"
          data-cy="consumption_per_month_in_winter-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.consumption_per_month_in_winter')"
          inputType="number"
          :required="toValidate ? true : false"
          :with-error="errors.consumption_per_month_in_winter"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('consumption_per_month_in_winter')"
          @input="removeErrorClass('consumption_per_month_in_winter')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mb-7" />

    <b-row class="mb-7">
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.presence_of_battery)"
          :options="referralOptionsPresenceOfBattery"
          :label="$t('referral.form.presence_of_battery')"
          data-cy="presence_of_battery-select"
          track-by="code"
          @select="onSelectPresenceOfBattery"
          :disabled="isWonReferral"
          :required="toValidate ? true : false"
          :with-error="errors.presence_of_battery"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('presence_of_battery')"
          @input="removeErrorClass('presence_of_battery')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <InputElement
          v-model="form.number_of_phases_under_contract"
          data-cy="number_of_phases_under_contract-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.number_of_phases_under_contract')"
          inputType="number"
          :required="toValidate ? true : false"
          :with-error="errors.number_of_phases_under_contract"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('number_of_phases_under_contract')"
          @input="removeErrorClass('number_of_phases_under_contract')"
        />
      </b-col>
      <b-col cols="6">
        <InputElement
          v-model="form.number_of_phases_actual"
          data-cy="number_of_phases_actual-input"
          :disabled="isWonReferral"
          :label="$t('referral.form.number_of_phases_actual')"
          inputType="number"
          :required="toValidate ? true : false"
          :with-error="errors.number_of_phases_actual"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('number_of_phases_actual')"
          @input="removeErrorClass('number_of_phases_actual')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.connection_type)"
          :options="referralOptionsConnectionTypes"
          :label="$t('referral.form.connection_type')"
          data-cy="connection_type-select"
          track-by="id"
          @select="onSelectConnectionType"
          :disabled="isWonReferral"
          :required="toValidate ? true : false"
          :with-error="errors.connection_type"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('connection_type')"
          @input="removeErrorClass('connection_type')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mb-7" />

    <b-row class="mb-7 mt-12">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.type_of_solar_panel_placement)"
          :options="referralOptionsTypesOfSolarPanelPlacement"
          :label="$t('referral.form.type_of_solar_panel_placement')"
          data-cy="type_of_solar_panel_placement-select"
          track-by="id"
          @select="onSelectTypeOfSolarPanelPlacement"
          :disabled="isWonReferral"
          :required="toValidate ? true : false"
          :with-error="errors.type_of_solar_panel_placement"
          :error-message="$t('validation.required')"
          @blur="removeErrorClass('type_of_solar_panel_placement')"
          @input="removeErrorClass('type_of_solar_panel_placement')"
        />
      </b-col>
    </b-row>

    <Brief v-if="referral.briefUrl" :url="referral.briefUrl"/>

    <b-row v-if="!isWonReferral">
      <b-col cols="6">
        <InputFile
          :label="$t('form.attachBrief')"
          :error="errors.brief"
          :allowed-ext="allowedBriefExt"
          @change="onChangeBrief"
        />
      </b-col>
      <b-col cols="6">
        <ButtonElement
          v-if="briefTemplate?.url"
          variant="primary"
          style="width: 100%;"
          :text="$t('downloadBriefTpl')"
          @click="dowloadBriefTemplate"
        ></ButtonElement>
      </b-col>
    </b-row>

    <div class="separator separator-dashed mb-8 mt-8" />

    <TextareaElement
      v-model="form.comment"
      data-cy="comment-input"
      class="component-input-textarea"
      :label="$t('label.comment')"
      @blur="removeErrorClass('comment')"
      @input="removeErrorClass('comment')"
      style="margin-bottom: 0;"
    />
  </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import TextareaElement from "@/components/form/TextareaElement";
import CleaveElement from "@/components/form/CleaveElement";
import InputFile from "@/components/form/InputFile";
import ButtonElement from "@/components/ButtonElement";
import axios from "axios"
import { documentFormData } from "@/views/employee/documents/gql/queries"
import dateMixin from "@/mixins/date.mixin";
import Brief from '../../_components/Brief';
import StatusItem from "@/components/StatusItem";
import ReadOnlyElement from "@/components/ReadOnlyElement";
import referralStatus from "@/configs/referralStatus.json";

export default {
  name: "ReferralForm",
  components: {
    InputElement,
    MultiselectElement,
    TextareaElement,
    CleaveElement,
    InputFile,
    ButtonElement,
    Brief,
    StatusItem,
    ReadOnlyElement,
  },

  mixins: [apolloMixin, dateMixin],

  props: {
    referral: {
      type: Object,
      default: () => ({}),
    },

    regions: {
      type: Array,
      default: () => [],
    },

    userHash: {
      type: String,
      default: "",
    },

    referralOptions: {
      type: Object,
      default: () => {},
    },

    // create|update
    formActionType: {
      type: String,
      default: 'create',
    },
  },

  data: () => ({
    form: {
      agentHash: "",
      firstName: "",
      phone: "38",
      region: null,
      comment: "",
      brief: null,
      building_area: "",
      roof_type: "",
      type_of_roof_covering: "",
      consumption_per_month_in_summer: "",
      consumption_per_month_in_winter: "",
      presence_of_battery: "",
      number_of_phases_under_contract: "",
      number_of_phases_actual: "",
      connection_type: "",
      type_of_solar_panel_placement: "",
    },
    errors: {
      firstName: false,
      phone: false,
      region: false,
      building_area: false,
      roof_type: false,
      type_of_roof_covering: false,
      consumption_per_month_in_summer: false,
      consumption_per_month_in_winter: false,
      presence_of_battery: false,
      number_of_phases_under_contract: false,
      number_of_phases_actual: false,
      connection_type: false,
      type_of_solar_panel_placement: false,
    },
    cleaveOptions: {
      phone: true,
      phoneRegionCode: "UA",
      delimiter: "-",
    },
    phoneMinLength: 11,
    phoneMaxLength: 13,
    commentMaxLength: 255,
    minPowerKw: 10,
    maxPowerKw: 1000,
    briefTemplate: {},
    allowedBriefExt: ["pdf", "doc", "docx"],
  }),

  computed: {
    ...mapState("referral", ["statuses"]),

    toValidate() {
      return this.formActionType === 'create' 
        || this.formActionType === 'update' && this.referral?.createdAt > 1720594800 ? true : false;
    },
    
    isExistReferral() {
      return Object.keys(this.referral).length;
    },

    isWonReferral() {
      return this.referral?.status === referralStatus.won;
    },

    phoneWithPlus() {
      return `+${this.form.phone}`;
    },

    phoneNumberLength() {
      const phoneRegExp = /\D/g;

      return this.form.phone.replace(phoneRegExp, "").length;
    },

    phoneErrorMsg() {
      return !this.phoneNumberLength
        ? this.$t("validation.required")
        : this.$t("validation.phoneLength");
    },

    locale() {
      return this.$i18n.locale;
    },

    referralOptionsRoofTypes() {
      return this.referralOptions?.options?.roof_types.map((item) => {
        item.label = this.$t(`referral.form.select.options.roof_types.${item.label}`);

        return item;
      })
    },

    referralOptionsTypesOfRoofCovering() {
      return this.referralOptions?.options?.types_of_roof_covering.map((item) => {
        item.label = this.$t(`referral.form.select.options.types_of_roof_covering.${item.label}`);

        return item;
      })
    },

    referralOptionsConnectionTypes() {
      return this.referralOptions?.options?.connection_types.map((item) => {
        item.label = this.$t(`referral.form.select.options.connection_types.${item.label}`);

        return item;
      })
    },

    referralOptionsTypesOfSolarPanelPlacement() {
      return this.referralOptions?.options?.types_of_solar_panel_placement.map((item) => {
        item.label = this.$t(`referral.form.select.options.types_of_solar_panel_placement.${item.label}`);

        return item;
      })
    },

    referralOptionsPresenceOfBattery() {
      return [
        {id: 1, label: this.$t(`referral.form.select.options.presence_of_battery._1`)},
        {id: 0, label: this.$t(`referral.form.select.options.presence_of_battery._0`)},
      ];
    },
  },

  watch: {
    userHash: {
      handler: "onChangeUserHash",
      immediate: true,
    },

    referral: {
      handler: "onChangeReferral",
      immediate: true,
      deep: true,
    },
  },

  async created() {
    await this.getBriefTeplate();
  },

  methods: {
    ...mapActions("publicReferral", ["createReferral"]),

    onChangeReferral() {
      if (this.isExistReferral) {
        const dateCreate = this.$dateConverter(this.referral.createdAt);
        const firstName = this.referral.name;
        const phone = this.referral.phone;
        const region = {...this.referral.region, name: this.referral.region.name["ua"]};
        const comment = this.referral.comment;
        const brief = this.form.brief;
        const status = this.statuses.find((status) => status.code === this.referral.status);
        const building_area = this.referral.buildingArea;
        const roof_type = this.referral.roofType ? {
          id: this.referral.roofType,
          label: this.$t(`referral.form.select.options.roof_types.${this.referral.roofType}`)
        } : "";

        const type_of_roof_covering = this.referral.typeOfRoofCovering ? {
          id: this.referral.typeOfRoofCovering,
          label: this.$t(`referral.form.select.options.types_of_roof_covering.${this.referral.typeOfRoofCovering}`)
        } : "";

        const consumption_per_month_in_summer = this.referral.consumptionPerMonthInSummer;
        const consumption_per_month_in_winter = this.referral.consumptionPerMonthInWinter;
        
        const presence_of_battery = this.referral.presenceOfBattery ? {
          id: this.referral.presenceOfBattery,
          label: this.$t(`referral.form.select.options.presence_of_battery._${this.referral.presenceOfBattery}`)
        } : "";

        const number_of_phases_under_contract = this.referral.numberOfPhasesUnderContract;
        const number_of_phases_actual = this.referral.numberOfPhasesActual;

        const connection_type = this.referral.connectionType ? {
          id: this.referral.connectionType,
          label: this.$t(`referral.form.select.options.connection_types.${this.referral.connectionType}`)
        } : "";

        const type_of_solar_panel_placement = this.referral.typeOfSolarPanelPlacement ? {
          id: this.referral.typeOfSolarPanelPlacement,
          label: this.$t(
            `referral.form.select.options.types_of_solar_panel_placement.${this.referral.typeOfSolarPanelPlacement}`
          )
        } : "";

        this.form = {
          dateCreate,
          firstName,
          phone,
          region,
          comment,
          brief,
          status,
          building_area,
          roof_type,
          type_of_roof_covering,
          consumption_per_month_in_summer,
          consumption_per_month_in_winter,
          presence_of_battery,
          number_of_phases_under_contract,
          number_of_phases_actual,
          connection_type,
          type_of_solar_panel_placement,
        };

      }
    },
    
    onChangeUserHash() {
      this.form.agentHash = this.userHash;
    },

    onChangePhone(phone) {
      this.form.phone = phone;
      this.removeErrorClass("phone");
    },

    onSelectRegion(region) {
      this.form.region = region;
    },

    onSelectRoofType(val) {
      this.form.roof_type = val
    },

    onSelectTypeOfRoofCovering(val) {
      this.form.type_of_roof_covering = val
    },

    onSelectPresenceOfBattery(val) {
      this.form.presence_of_battery = val
    },
    
    onSelectConnectionType(val) {
      this.form.connection_type = val
    },

    onSelectTypeOfSolarPanelPlacement(val) {
      this.form.type_of_solar_panel_placement = val
    },

    onChangeBrief(e) {
      if(typeof e !== "undefined") {
        const briefExt = e.name.split(".")[e.name.split(".").length - 1].toLowerCase();

        this.errors.brief = !this.allowedBriefExt.includes(briefExt);
      }
      
      this.form.brief = e
    },

    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.phone = !this.phoneNumberLength;
      this.errors.phone =
        this.phoneNumberLength < this.phoneMinLength ||
        this.phoneNumberLength > this.phoneMaxLength;
      this.errors.region = !this.form.region;

      if(this.form.brief) {
        const briefExt = this.form.brief.name.split(".")[this.form.brief.name.split(".").length - 1].toLowerCase();

        this.errors.brief = !this.allowedBriefExt.includes(briefExt);
      }

      if(this.formActionType === 'create'
          || this.formActionType === 'update' && this.referral?.createdAt > 1720594800
      ) {
        this.errors.building_area = !this.form.building_area;
        this.errors.roof_type = !this.form.roof_type?.id;
        this.errors.type_of_roof_covering = !this.form.type_of_roof_covering?.id;
        this.errors.consumption_per_month_in_summer = !this.form.consumption_per_month_in_summer;
        this.errors.consumption_per_month_in_winter = !this.form.consumption_per_month_in_winter;
        this.errors.presence_of_battery = !this.form.presence_of_battery?.id;
        this.errors.number_of_phases_under_contract = !this.form.number_of_phases_under_contract;
        this.errors.number_of_phases_actual = !this.form.number_of_phases_actual;
        this.errors.connection_type = !this.form.connection_type?.id;
        this.errors.type_of_solar_panel_placement = !this.form.type_of_solar_panel_placement?.id;
      }

      return !Object.values(this.errors).some((error) => error);
    },

    submitForm() {
      if (!this.isValidForm()) return;

      const referralData = {
        agentHash: this.form.agentHash,
        name: this.form.firstName,
        phone: this.form.phone,
        regionId: this.form.region.id,
        comment: this.form.comment,
        brief: this.form.brief,
        buildingArea: parseFloat(this.form.building_area),
        roofType: this.form.roof_type.id,
        typeOfRoofCovering: this.form.type_of_roof_covering.id,
        consumptionPerMonthInSummer: parseInt(this.form.consumption_per_month_in_summer),
        consumptionPerMonthInWinter: parseInt(this.form.consumption_per_month_in_winter),
        presenceOfBattery: this.form.presence_of_battery.id,
        numberOfPhasesUnderContract: parseInt(this.form.number_of_phases_under_contract),
        numberOfPhasesActual: parseInt(this.form.number_of_phases_actual),
        connectionType: this.form.connection_type.id,
        typeOfSolarPanelPlacement: this.form.type_of_solar_panel_placement.id,
      };

      this.$emit("submitForm", referralData);
    },

    async getBriefTeplate() {
      const response = await this.$get(documentFormData, { name: "brief" });

      this.briefTemplate = response.document;
    },

    dowloadBriefTemplate() {
      if(this.briefTemplate?.url) {

        axios.get(`storage/private/${this.briefTemplate.url}`, {
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");

          link.href = href;
          link.setAttribute("download", this.briefTemplate.filename);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-form {
  .component-input-textarea::v-deep {
    .app-textarea {
      height: 93px;
    }
  }
}

[data-cy=number_of_phases_under_contract-input]::v-deep label,
[data-cy=number_of_phases_actual-input]::v-deep label {
  margin-top: -20px;
  margin-right: 20px;
}
</style>

import gql from "graphql-tag";

export const updateReferral = gql`
  mutation updateReferral(
    $id: ID!
    $name: String
    $phone: String
    $regionId: ID
    $comment: String
    $brief: Upload
    $buildingArea: Int
    $roofType: String
    $typeOfRoofCovering: String
    $consumptionPerMonthInSummer: Int
    $consumptionPerMonthInWinter: Int
    $presenceOfBattery: Int
    $numberOfPhasesUnderContract: Int
    $numberOfPhasesActual: Int
    $connectionType: String
    $typeOfSolarPanelPlacement: String
  ) {
    updateReferral(
      id: $id
      name: $name
      phone: $phone
      regionId: $regionId
      comment: $comment
      brief: $brief
      buildingArea: $buildingArea
      roofType: $roofType
      typeOfRoofCovering: $typeOfRoofCovering
      consumptionPerMonthInSummer: $consumptionPerMonthInSummer
      consumptionPerMonthInWinter: $consumptionPerMonthInWinter
      presenceOfBattery: $presenceOfBattery
      numberOfPhasesUnderContract: $numberOfPhasesUnderContract
      numberOfPhasesActual: $numberOfPhasesActual
      connectionType: $connectionType
      typeOfSolarPanelPlacement: $typeOfSolarPanelPlacement
    ) {
      id
      name
      phone
      region {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      stationPlanned
      stationInstalled
      transactions {
        id
        type
        amount
        createdAt
        comment
        user {
          id
          firstName
          lastName
        }
      }
      accrued
      comment
      status
      brief
      briefUrl
      buildingArea
      roofType
      typeOfRoofCovering
      consumptionPerMonthInSummer
      consumptionPerMonthInWinter
      presenceOfBattery
      numberOfPhasesUnderContract
      numberOfPhasesActual
      connectionType
      typeOfSolarPanelPlacement
    }
  }
`;
import gql from "graphql-tag";

export const updateReferral = gql`
  mutation updateReferral(
    $id: ID!
    $name: String
    $phone: String
    $email: String!
    $regionId: ID
    $stationPlanned: Float
    $stationInstalled: Float
    $userId: ID
    $comment: String
    $status: String
    $brief: Upload
    $buildingArea: Int
    $roofType: String
    $typeOfRoofCovering: String
    $consumptionPerMonthInSummer: Int
    $consumptionPerMonthInWinter: Int
    $presenceOfBattery: Int
    $numberOfPhasesUnderContract: Int
    $numberOfPhasesActual: Int
    $connectionType: String
    $typeOfSolarPanelPlacement: String
  ) {
    updateReferral(
      id: $id
      name: $name
      phone: $phone
      email: $email
      regionId: $regionId
      stationPlanned: $stationPlanned
      stationInstalled: $stationInstalled
      userId: $userId
      comment: $comment
      status: $status
      brief: $brief
      buildingArea: $buildingArea
      roofType: $roofType
      typeOfRoofCovering: $typeOfRoofCovering
      consumptionPerMonthInSummer: $consumptionPerMonthInSummer
      consumptionPerMonthInWinter: $consumptionPerMonthInWinter
      presenceOfBattery: $presenceOfBattery
      numberOfPhasesUnderContract: $numberOfPhasesUnderContract
      numberOfPhasesActual: $numberOfPhasesActual
      connectionType: $connectionType
      typeOfSolarPanelPlacement: $typeOfSolarPanelPlacement
    ) {
      id
      name
      phone
      email
      region {
        id
        name
      }
      stationPlanned
      stationInstalled
      user {
        id
        detail {
          __typename
          ... on Agent {
            phone
          }
        }
      }
      comment
      status
      brief
      briefUrl
      buildingArea
      roofType
      typeOfRoofCovering
      consumptionPerMonthInSummer
      consumptionPerMonthInWinter
      presenceOfBattery
      numberOfPhasesUnderContract
      numberOfPhasesActual
      connectionType
      typeOfSolarPanelPlacement
    }
  }
`;

export const deleteReferral = gql`
  mutation deleteReferral($id: ID!) {
    deleteReferral(id: $id) {
      id
    }
  }
`;

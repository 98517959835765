import gql from "graphql-tag";

export const agentReferrals = gql`
  query referrals($orderBy: OrderBy, $filters: Filter, $page: Int, $perPage: Int) {
    referrals(orderBy: $orderBy, filters: $filters, page: $page, first: $perPage) {
      paginatorInfo {
        total
        perPage
        currentPage
        lastPage
      }

      data {
        id
        name
        phone
        email
        createdAt
        region {
          id
          name
        }
        comment
        status
        accrued
        bx24Opportunity
        bx24CurrencyId
        bx24PowerByInvertor
      }
    }

    regions {
      id
      name
    }
  }
`;

import gql from "graphql-tag";

export const requiredData = gql`
  query requiredData {
    agents {
      data {
        user {
          id
          firstName
          lastName
        }
      }
    }

    regions {
      id
      name
    }

    referralOptions {
      options
    }
  }
`;

<template>
  <div class="referral-details-row">
    <KTCard v-if="referralView" data-cy="referral-details-page" class="referral-details-page">
      <PageModal
        ref="transactionsModal"
        modal-id="transactionsModal"
        :title="$t('label.accruals.history')"
        modal-size="lg"
        is-centered
      >
        <OurTable
          table-classes="our-table table-responsive"
          :fields="fields"
          :items="agentTransactions"
        />

        <template v-slot:footer>
          <ButtonElement variant="text-primary" text="back" @click="closeTransactionsModal" />
        </template>
      </PageModal>

      <b-row class="mb-7">
        <b-col cols="12">
          <div class="accruals">{{ $t("label.accruals.order") }} {{ accrualsAmount }}</div>
          <b-link
            v-if="referral.transactions.length"
            class="accruals-link"
            @click="showTransactionsModal"
          >
            {{ $t("button.accrualsHistory") }}
          </b-link>
        </b-col>
      </b-row>

      <div class="separator separator-dashed my-7" />

      <div v-if="userAgentType === 'company'">
        <ReferralForm
          ref="referralForm"
          :referral="referral"
          :user-hash="userHash"
          :regions="regions"
          formActionType="update"
          @submitForm="onSubmitForm"
        />
      </div>
      <div v-else>

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement :label="$t('label.firstName')" :text="referralView.name" />
          </b-col>

          <b-col cols="6">
            <ReadOnlyElement :label="$t('label.phone')" :text="referralView.phone" />
          </b-col>
        </b-row>

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement :label="$t('label.region')" :text="referralView.region" />
          </b-col>

          <b-col cols="6">
            <ReadOnlyElement :label="$t('label.email')" :text="referralView.email" />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.building_area')"
              :text="referralView.buildingArea"
            />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.roof_type')"
              :text="referralView.roofType"
            />
          </b-col>

          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.type_of_roof_covering')"
              :text="referralView.typeOfRoofCovering"
            />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.consumption_per_month_in_summer')"
              :text="referralView.consumptionPerMonthInSummer"
            />
          </b-col>

          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.consumption_per_month_in_winter')"
              :text="referralView.consumptionPerMonthInWinter"
            />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.presence_of_battery')"
              :text="referralView.presenceOfBattery"
            />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.number_of_phases_under_contract')"
              :text="referralView.numberOfPhasesUnderContract"
            />
          </b-col>

          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.number_of_phases_actual')"
              :text="referralView.numberOfPhasesActual"
            />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row class="mb-7">
          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.connection_type')"
              :text="referralView.connectionType"
            />
          </b-col>

          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('referral.form.type_of_solar_panel_placement')"
              :text="referralView.typeOfSolarPanelPlacement"
            />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row>
          <b-col cols="6">
            <ReadOnlyElement
              :label="$t('label.date')"
              :text="$dateConverter(referralView.dateCreate)"
            />
          </b-col>

          <b-col cols="6">
            <ReadOnlyElement :label="$t('label.status')">
              <template v-slot:body>
                <StatusItem
                  :color="referralView.status.color"
                  :label="referralView.status.label"
                  class="text"
                />
              </template>
            </ReadOnlyElement>
          </b-col>

          <b-col cols="6" v-if="userAgentType === 'company'">
            <ReadOnlyElement
              :label="$t('label.agent._')"
              :text="`${referral.user.firstName} ${referral.user.lastName}`"
            />
          </b-col>
        </b-row>

        <div class="separator separator-dashed my-7" />

        <b-row>
          <b-col>
            <ReadOnlyElement :label="$t('label.comment')" :text="referralView.comment" />
          </b-col>
        </b-row>

        <div v-if="referral.briefUrl" class="separator separator-dashed my-7" />
        <Brief v-if="referral.briefUrl" :url="referral.briefUrl" />
      </div>

      <template v-if="userAgentType === 'company'" v-slot:footer>
        <div class="d-flex justify-content-start">
          <ButtonElement
            data-cy="submit-btn"
            variant="primary"
            text="save"
            class="mr-3"
            @click="onClickSubmit"
          />

          <ButtonElement data-cy="back-btn" variant="text-primary" text="back" @click="onClickBack" />
        </div>
      </template>
      
    </KTCard>

    <div v-if="referral?.manager" class="referral-details-manager">
      <div v-show="!liveChat.show" class="referral-details-manager-inner">
        <div class="referral-details-manager-body">
          <div class="referral-details-manager-left">
            <div class="referral-details-manager-avatar">
              <img :src="referral?.manager.photo" alt="">
            </div>
          </div>
          <div class="referral-details-manager-right">
            <div class="referral-details-manager-name">{{ referral?.manager?.name }}</div>
            <div class="referral-details-manager-info">
              {{ $t('referral.form.manager_info_label') }}: {{ referral?.manager?.deal_id }}
            </div>
          </div>
        </div>
        <div class="referral-details-manager-footer">
          <ButtonElement
            variant="primary"
            @click="openChat"
          >
            <SvgIcon iconFolder="Communication" iconName="Chat"/>
            {{ $t('referral.form.open_chat') }}
          </ButtonElement>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import dateMixin from "@/mixins/date.mixin";
import { referralById } from "./gql/queries";

import KTCard from "@/components/card/KTCard";
import StatusItem from "@/components/StatusItem";
import ReadOnlyElement from "@/components/ReadOnlyElement";
import SvgIcon from "@/components/svg/SvgIcon";
import PageModal from "@/components/modal/PageModal";
import OurTable from "@/components/table/OurTable";
import ButtonElement from "@/components/ButtonElement";
import Brief from "../_components/Brief.vue"
import ReferralForm from '../add/components/ReferralForm';
import { regionsQuery } from "@/views/referral/form/gql/queries";
import { updateReferral } from "./gql/mutations";
// import storage from "@/services/storage.service";

const CONTENT_WIDTH = 1200;

export default {
  name: "ReferralDetails",
  components: {
    KTCard,
    StatusItem,
    ReadOnlyElement,
    SvgIcon,
    PageModal,
    OurTable,
    ButtonElement,
    Brief,
    ReferralForm,
  },

  mixins: [apolloMixin, dateMixin],

  data() {
    return {
      regions: [],
      referralId: null,
      referral: null,
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "name",
          label: this.$t("label.agent._"),
          type: "link",
        },
        {
          key: "amount",
          label: this.$t("label.amount"),
          type: "amount",
          thClass: "text-right",
          tdAttr: {
            style: "width: 20%; max-width: 90px; text-align: right;",
          },
          filter: {
            component: "input",
            type: "number",
            operator: "like",
            classes: "w-50",
          },
        },
        {
          key: "type",
          label: this.$t("label.type"),
          tdAttr: {
            style: "width: 30%; max-width: 100px;",
          },
          filter: {
            component: "select",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "comment",
          label: this.$t("label.comment"),
          tdAttr: {
            style: "width: 40%; max-width: 110px;",
          },
        },
      ],
      liveChat: {
        show: false,
        scriptUrl: '',
      }
    };
  },

  computed: {
    ...mapState("referral", ["statuses"]),
    ...mapState("transaction", ["types"]),
    ...mapState("currency", ["currency"]),
    ...mapGetters(["userId", "userAgentType", "userHash"]),

    referralView() {
      let referralData = null;

      if (this.referral) {
        const name = this.referral.name;
        const phone = this.referral.phone;
        // TODO: fix when will be country switch
        // const region = this.referral.region.name[this.locale];
        const region = this.referral.region.name["ua"];
        const email = this.referral.email;
        const comment = this.referral.comment;
        const dateCreate = this.referral.createdAt;
        const status = this.statuses.find((status) => status.code === this.referral.status);
        const accrued = this.referral.accrued;
        const transactions = this.referral.transactions;
        const buildingArea = this.referral.buildingArea;
        const roofType = this.referral.roofType ? 
          this.$t(`referral.form.select.options.roof_types.${this.referral.roofType}`) : "";

        const typeOfRoofCovering = this.referral.typeOfRoofCovering ?
          this.$t(`referral.form.select.options.types_of_roof_covering.${this.referral.typeOfRoofCovering}`) : "";

        const consumptionPerMonthInSummer = this.referral.consumptionPerMonthInSummer;
        const consumptionPerMonthInWinter = this.referral.consumptionPerMonthInWinter;
        
        const presenceOfBattery = this.referral.presenceOfBattery ?
          this.$t(`referral.form.select.options.presence_of_battery._${this.referral.presenceOfBattery}`) : "";

        const numberOfPhasesUnderContract = this.referral.numberOfPhasesUnderContract;
        const numberOfPhasesActual = this.referral.numberOfPhasesActual;

        const connectionType = this.referral.connectionType ?
          this.$t(`referral.form.select.options.connection_types.${this.referral.connectionType}`) : "";

        const typeOfSolarPanelPlacement = this.referral.typeOfSolarPanelPlacement ?
           this.$t(
            `referral.form.select.options.types_of_solar_panel_placement.${this.referral.typeOfSolarPanelPlacement}`
          ) : "";

        referralData = {
          name,
          phone,
          region,
          email,
          comment,
          status,
          dateCreate,
          accrued,
          transactions,
          buildingArea,
          roofType,
          typeOfRoofCovering,
          consumptionPerMonthInSummer,
          consumptionPerMonthInWinter,
          presenceOfBattery,
          numberOfPhasesUnderContract,
          numberOfPhasesActual,
          connectionType,
          typeOfSolarPanelPlacement,
        };
      }

      return referralData;
    },

    agentTransactions() {
      return this.referral.transactions
        .map((transaction) => {
          const dateCreate = transaction.createdAt;
          const amount = {
            amount: transaction.amount,
            type: transaction.type,
          };

          const type = this.types.find((type) => type.code === transaction.type)?.label;
          const comment = transaction.comment || "–";
          const id = transaction.user ? transaction.user.id : this.referral.user.id;
         
          let route;

          if(transaction.user) {
            route = id == this.userId ? {
              name: 'ProfileEdit',
            } : {
              name: 'CompanyAgentEdit',
              params: { id: id },
            }
          } else {
            route = {
              name: 'CompanyAgentEdit',
              params: { id: id },
            }
          }

          const name = {
            title: transaction.user
            ? `${transaction.user.firstName} ${transaction.user.lastName}`
            : `${this.referral.user.firstName} ${this.referral.user.lastName}`,
            route: route,
          };

          return {
            dateCreate,
            name,
            amount,
            type,
            comment,
          };
        })
        .reverse();
    },

    locale() {
      return this.$i18n.locale;
    },

    accrualsAmount() {
      return `${this.referralView.accrued} ${this.currency}`;
    },
  },

  async created() {
    this.referralId = this.$route.params.id;
    
    await this.getReferral();
    await this.getRegions();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);

    window.addEventListener('onBitrixLiveChat', this.handleLiveChat);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();

    window.removeEventListener('onBitrixLiveChat', this.handleLiveChat);

    const chatWidgetScript = document.querySelector(`script[src='${this.liveChat.scriptUrl}']`);

    if(chatWidgetScript) {
      window.BX.LiveChat.closeLiveChat();
      chatWidgetScript.remove();
      window.BX.remove();
      delete window.BX;
      delete window.BXLiveChat;
      document.querySelector('.b24-widget-button-wrapper').parentElement.parentElement.remove();
    }
  },

  methods: {
    async getReferral() {
      const id = this.$route.params.id;
      const { referral } = await this.$get(referralById, { id });

      this.referral = referral;
    },

    showTransactionsModal() {
      this.$refs.transactionsModal.showModal();
    },

    closeTransactionsModal() {
      this.$refs.transactionsModal.closeModal();
    },

    async getRegions() {
      const { regions } = await this.$get(regionsQuery);

      // TODO: fix when will be country switch
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));
      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
    },

    onClickSubmit() {
      this.$refs.referralForm.submitForm();
    },

    async onSubmitForm(referralData) {
      referralData = {
        ...referralData,
        id: this.referralId,
      };

      const response = await this.$post(updateReferral, referralData);

      this.referral.briefUrl = response.updateReferral.briefUrl;
    },

    onClickBack() {
      this.$router.push({ name: "AgentReferrals" });
    },

    openChat() {
      const url = "https://crm.atmo.pro/upload/crm/site_button/loader_5_dpkd16.js";
      const hash = `?${((Date.now() / 60000) | 0)}`;
      const fullUrl = url + hash;

      this.liveChat.scriptUrl = fullUrl;

      const scriptNode = document.querySelector(`script[src='${fullUrl}']`);

      if(!scriptNode) {
        const script = document.createElement("script");

        script.src = fullUrl;
        script.async = true;

        const firstScript = document.getElementsByTagName("script")[0];

        firstScript.parentNode.insertBefore(script, firstScript);
      } else {
        this.liveChat.show = true;
        window.BX.LiveChat.openLiveChat();
      }
      
    },

    handleLiveChat(e) {
      const widget = e.detail.widget;

      window.BX.LiveChat.openLiveChat();

      this.liveChat.show = true;

      widget.subscribe({
        type: window.BX.LiveChatWidget.SubscriptionType.widgetClose,
        callback: () => {
          this.liveChat.show = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-details {

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 48px;

    @media screen and (max-width: 820px) {
      flex-direction: column;
    }
  }

  &-page {
    width: 60%;

    @media screen and (max-width: 820px) {
      width: 100%;
    }
  }

  &-manager {
    width: 34%;
    position: sticky;
    top: 1rem;

    @media screen and (max-width: 820px) {
      width: 100%;
      order: -1;
      margin-bottom: 2rem;
      position: relative;
    }

    &-inner {
      box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
      background: #fff;
      border-radius: 0.42rem;
      padding: 24px 20px;
    }

    &-body {
      display: flex;
      gap: 0 16px;
    }

    &-avatar {
      width: 56px;
      height: 56px;
      line-height: 1px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }

    &-name {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 4px;
    }

    &-info {
      color: #737484;
    }

    &-footer {
      margin-top: 16px;

      .svg-icon {
        padding: 0;
        margin-right: 8px;
      }

      ::v-deep svg {
        fill: #ffffff;
        width: 22px !important;
        height: 22px !important;
      }
    }

  }
}
.accruals {
  font-size: 1.2rem;
  font-weight: 700;

  &-link {
    font-size: 1rem;
    font-weight: 500;
  }
}

</style>

<style lang="scss">
@media screen and (min-width: 1024px) {
  .bx-livechat-position-bottom-right {
    bottom: 50px !important;
  }
}

.b24-widget-button-wrapper {
  display: none !important;
}
</style>
